import React  from "react";

export default function About() {

    return (
        <div className="text-break w-75">

            <h1>
                מפתח המקורות
            </h1>

            <p>
                <b>
                    אוצר הגדולים

                </b>
                - ר' נפתלי יעקב הכהן

            </p>
            <p>
                <b>
                    אוצר ישראל

                </b>
                - ר' יהודה דוד אייזענשטיין

            </p>
            <p>
                <b>
                    אור החיים

                </b>
                - ר' חיים ב"ר יוסף מיכל

            </p>
            <p>
                <b>
                    אנציקלופדיה לתולדות גדולי ישראל

                </b>
                - ד"ר מרדכי מרגליות

            </p>
            <p>
                <b>
                    בית עקד ספרים

                </b>
                - פרידברג

            </p>
            <p>
                <b>
                    דור דור ודורשיו

                </b>
                - ר' יוסף לונשטיין

            </p>
            <p>
                <b>
                    יוחסין השלם

                </b>
                - ר' אברהם זכות

            </p>
            <p>
                <b>
                    סדר הדורות

                </b>
                - ר' יחיאל הלפרין

            </p>
            <p>
                <b>
                    שלשלת הקבלה

                </b>
                - ר' גדליה אבן יחייא

            </p>
            <p>
                <b>
                    שם הגדולים

                </b>
                - ר' חיים יוסף דוד אזולאי

            </p>
            <p>
                <b>
                    שרי האלף

                </b>
                - ר' מנחם כשר

            </p>

        </div>
    );
}
