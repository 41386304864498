import React from "react";


export default function About() {

    return (
        <div className="text-break w-75">
            <p>
                <h4>
                    קרית חנוך מבשרת ציון
                </h4>
                כולל אברכים
                ב"ה כסלו תשמ"ח
            </p>
            <p>
                <h2>
                    זכרון לאברהם
                </h2>

                עברו כבר ארבע שנים מאז הלך מאתנו ר' אברהם בראונר ז"ל למנוחות, והשאיר אחריו את משפחתו לדורותיה לאנחות, ועדיין ניצבת ועומדת עמנו רוחו באיתנותה, כבימי מלחמת הגבורה שלו על צורתו כאיש המעלה, כל עוד נשמת חיים באפו.
                המדריך לתולדות גדולי ישראל מתקופת הגאונים ועד דורנו, חובר על ידי תוך כדי לימודו בבית מדרשנו, בעמל רב ובשקידה מיוחדת, כדי לתת ביד חבריו כלי עזר נוח ושימושי לפתיחת הכרתם את העומדים מאחורי שמות (מחברים וספרים) שעמהם נפגשים בלימוד בבית המדרש.
                תפוצתו של המדריך והדרישה להופעתו מחדש מוכיחים שאכן יש צורך אמיתי ללומדי התורה במדריך זה. רבים מהמתוועדים לסדר הדורות שבחוברת, המשיכו להעמיק את ידיעותיהם בנושא זה, ושילבוהו לתוך סדר הלימוד לעצמם ולתלמידיהם.
                יהיו נא שפתיו דובבות בקבר, ויזכה לעי"נ מכח כוחו בהרבצת התורה. נהיה אנחנו לו לפה לברך את הוריו שיזכו לאוי"ט בבריות גופא ונהורא מעליא, ולברך את רותי שתחי' המגדלת את בניהם ובנותיהם לתורה ולדרך ארץ בגבורתה ובעוז רוחה, שתראה ברכה והצלחה בכל מעשי ידיה, עד כי יבוא שילה.
                בצפיה לגאולה שלימה
                אורי כהן.

            </p>
        </div>
    );
}
